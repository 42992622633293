<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title class="d-flex align-center">
          Select Date Range:
          <DateRangePicker
            :ranges="ranges"
            v-model="dateRange"
            opens="right"
            :singleDatePicker="false"
            :maxDate="new Date()"
            @update="debounceFetching"
          >
            <template v-slot:input="picker">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
          </DateRangePicker>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card>
        <OrderChartsLine :lineChartData="ordersStatistics" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { API } from "../../api";
import OrderChartsLine from "./OrderChartsLine.vue";

export default {
  name: "OrdersCharts",
  components: {
    DateRangePicker,
    OrderChartsLine,
  },
  data() {
    return {
      ordersStatistics: null,
      dateRange: {
        startDate: new Date(new Date().getTime() - 8 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        previousPage: 0,
      },
      loading: false,
    };
  },
  filters: {
    date(date) {
      if (!date) return "";
      return date.toISOString().substr(0, 10).split("-").reverse().join(".");
    },
  },
  created() {
    this.getOrders();
  },
  computed: {
    ranges() {
      const startOfDay = new Date(new Date().setHours(2, 0, 0, 0));
      const endOfDay = new Date(new Date().setHours(23, 59, 59, 999));

      const thisMonthStart = new Date(
        startOfDay.getFullYear(),
        startOfDay.getMonth(),
        1
      );
      const thisMonthEnd = new Date(
        endOfDay.getFullYear(),
        endOfDay.getMonth() + 1,
        0
      );

      return {
        Today: [startOfDay, endOfDay],
        "Last 7 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 7
          ),
          endOfDay,
        ],
        "Last 30 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 30
          ),
          endOfDay,
        ],
        "This month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(startOfDay.getFullYear(), 0, 1),
          new Date(endOfDay.getFullYear(), 11, 31),
        ],
        "Last month": [
          new Date(startOfDay.getFullYear(), startOfDay.getMonth() - 1, 1),
          new Date(endOfDay.getFullYear(), endOfDay.getMonth(), 0),
        ],
        "Last Year": [
          new Date(startOfDay.getFullYear() - 1, 0, 1),
          new Date(endOfDay.getFullYear() - 1, 11, 31),
        ],
      };
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getOrders();
      }, 500);
    },
    async getOrders() {
      this.loading = true;

      try {
        const { data } = await API.getOrdersStatistics({
          ...this.dateRange,
        });

        this.ordersStatistics = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
