import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex align-center"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_vm._v("List of Orders")]),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{staticClass:"ml-4",attrs:{"items":_vm.types,"item-text":"name","item-value":"id","label":"Select order type","clearable":""},on:{"change":_vm.debounceFetching},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search by invoice number or product name","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalAmount,"footer-props":{
      'items-per-page-options': _vm.$options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer","color":"blue"},on:{"click":function($event){return _vm.showOrderDetails(item)}}},[_vm._v(" "+_vm._s(item.type === "printTemplate" ? "Print Template" : "Additional Tree")+" ")])]}},{key:"item.stripeInvoiceId",fn:function(ref){
    var item = ref.item;
return [(item.stripeInvoiceNumber)?_c('span',[_vm._v(" "+_vm._s(item.stripeInvoiceNumber)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.stripeInvoiceId)+" ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":_vm.stripeDashboardUrl(item.stripeInvoiceId),"target":"_blank"}},on),[_c(VIcon,[_vm._v("link")])],1)]}}],null,true)},[_c('span',[_vm._v("Stripe")])])]}},{key:"item.ownedBy",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Company', params: { id: item.ownedBy.id } }}},[_vm._v(" "+_vm._s(item.ownedBy.name)+" ")])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}],null,true)}),_c(VDialog,{attrs:{"max-width":"900"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.selectedOrder)?_c(VCard,[_c(VCardTitle,[_vm._v("Order Details")]),_c(VCardText,[_c(VList,[_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v("Type")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.selectedOrder.type === "printTemplate" ? "Print Template" : "Additional Tree")+" ")])],1),_c(VDivider),_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v("Company")]),_c(VListItemSubtitle,[_c('router-link',{attrs:{"to":{
                  name: 'Company',
                  params: { id: _vm.selectedOrder.ownedBy.id },
                }}},[_vm._v(" "+_vm._s(_vm.selectedOrder.ownedBy.name)+" ")])],1)],1),(_vm.selectedOrder.email)?_c(VDivider):_vm._e(),(_vm.selectedOrder.email)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" User email ")]),_c(VListItemSubtitle,[_c('span',{ref:"email"},[_vm._v(_vm._s(_vm.selectedOrder.email))]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard($event, 'email')}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})])]}}],null,false,182183189)},[_c('span',[_vm._v("Copy")])])],1)],1):_vm._e(),_c(VDivider),(_vm.selectedOrder.forest)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v("Forest")]),_c(VListItemSubtitle,[_c('router-link',{attrs:{"to":{
                  name: 'Forest',
                  params: { id: _vm.selectedOrder.forest.id },
                }}},[_vm._v(" "+_vm._s(_vm.selectedOrder.forest.name)+" ")])],1)],1):_vm._e(),(_vm.selectedOrder.stripeProductName)?_c(VDivider):_vm._e(),(_vm.selectedOrder.stripeProductName)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v("Product")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.selectedOrder.stripeProductName)+" ")])],1):_vm._e(),_c(VDivider),_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" Ordered At ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.formatDate(_vm.selectedOrder.createdAt))+" ")])],1),(_vm.selectedOrder.stripeInvoiceId)?_c(VDivider):_vm._e(),(_vm.selectedOrder.stripeInvoiceId)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" Invoice ID ")]),_c(VListItemSubtitle,[_c('span',{ref:"stripeInvoiceId"},[_vm._v(" "+_vm._s(_vm.selectedOrder.stripeInvoiceId)+" ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard($event, 'stripeInvoiceId')}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})])]}}],null,false,4276223660)},[_c('span',[_vm._v("Copy")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":_vm.stripeDashboardUrl(_vm.selectedOrder.stripeInvoiceId),"target":"_blank"}},on),[_c(VIcon,[_vm._v("link")])],1)]}}],null,false,2607765491)},[_c('span',[_vm._v("Stripe")])])],1)],1):_vm._e(),(_vm.selectedOrder.stripeInvoiceNumber)?_c(VDivider):_vm._e(),(_vm.selectedOrder.stripeInvoiceNumber)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" Invoice Number ")]),_c(VListItemSubtitle,[_c('span',{ref:"stripeInvoiceNumber"},[_vm._v(" "+_vm._s(_vm.selectedOrder.stripeInvoiceNumber)+" ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard($event, 'stripeInvoiceNumber')}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})])]}}],null,false,3041334402)},[_c('span',[_vm._v("Copy")])])],1)],1):_vm._e(),(_vm.selectedOrder.type === 'printTemplate')?_c(VDivider):_vm._e(),(_vm.selectedOrder.type === 'printTemplate')?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v("Price")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.selectedOrder.amount / 100)+" "+_vm._s(_vm.selectedOrder.currency)+" ")])],1):_vm._e(),(_vm.selectedOrder.fileUrl)?_c(VDivider):_vm._e(),(_vm.selectedOrder.fileUrl)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" Print Template File ")]),_c(VListItemSubtitle,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":_vm.selectedOrder.fileUrl,"target":"_blank"}},on),[_c(VIcon,[_vm._v("file_present")])],1)]}}],null,false,716415582)},[_c('span',[_vm._v("File")])])],1)],1):_vm._e(),(_vm.selectedOrder.templateConfiguration)?_c(VDivider):_vm._e(),(_vm.selectedOrder.templateConfiguration)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" Template Configuration ")]),_c(VListItemSubtitle,[_c(VList,[_vm._l((_vm.selectedOrder.templateConfiguration),function(value,key){return [_c(VListItem,{key:key},[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" "+_vm._s(_vm.formatKey(key))+" ")]),(typeof value !== 'object')?_c(VListItemSubtitle,[(value)?_c('span',[(_vm.isUrl(value))?_c('span',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":value,"target":"_blank"}},on),[_c(VIcon,[_vm._v("file_present")])],1)]}}],null,true)},[_c('span',[_vm._v("Logo")])])],1):_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(value))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(value))+" ")]),(value && key !== Object.keys(value).length - 1)?_c(VDivider):_vm._e()],1):_c(VListItemSubtitle,[_vm._l((value),function(value,key){return [_c(VListItem,{key:key},[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" "+_vm._s(_vm.formatKey(key))+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.formatValue(value))+" "),(
                                value && key !== Object.keys(value).length - 1
                              )?_c(VDivider):_vm._e()],1)],1)]})],2)],1)]})],2)],1)],1):_vm._e(),(_vm.selectedOrder.shipping)?_c(VDivider):_vm._e(),(_vm.selectedOrder.shipping)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v("Shipping")]),_c(VListItemSubtitle,[_c(VList,[_vm._l((_vm.selectedOrder.shipping),function(value,key){return [_c(VListItem,{key:key},[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" "+_vm._s(_vm.formatKey(key))+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.formatValue(value))+" "),(value && key !== Object.keys(value).length - 1)?_c(VDivider):_vm._e()],1)],1)]})],2)],1)],1):_vm._e(),(_vm.selectedOrder.references)?_c(VDivider):_vm._e(),(_vm.selectedOrder.references)?_c(VListItem,[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" References ")]),_c(VListItemSubtitle,[_c(VList,[_vm._l((_vm.selectedOrder.references),function(value,key){return [_c(VListItem,{key:key},[_c(VListItemTitle,{staticClass:"list-title"},[_vm._v(" "+_vm._s(_vm.formatKey(key))+" ")]),_c(VListItemSubtitle,[(_vm.isUrl(value))?_c('span',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":value,"target":"_blank"}},on),[_c(VIcon,[_vm._v("link")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.formatKey(key)))])])],1):_c('span',[_vm._v(" "+_vm._s(_vm.formatValue(value))+" ")]),(value && key !== Object.keys(value).length - 1)?_c(VDivider):_vm._e()],1)],1)]})],2)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }