<template>
  <v-card>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="12" md="4">List of Orders</v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="selectedType"
            :items="types"
            item-text="name"
            item-value="id"
            label="Select order type"
            clearable
            class="ml-4"
            @change="debounceFetching"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by invoice number or product name"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalAmount"
      :footer-props="{
        'items-per-page-options': $options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      }"
    >
      <template v-slot:[`item.type`]="{ item }">
        <span
          @click="showOrderDetails(item)"
          style="cursor: pointer; color: blue"
        >
          {{
            item.type === "printTemplate" ? "Print Template" : "Additional Tree"
          }}
        </span>
      </template>
      <template v-slot:[`item.stripeInvoiceId`]="{ item }">
        <span v-if="item.stripeInvoiceNumber">
          {{ item.stripeInvoiceNumber }}
        </span>
        <span v-else>
          {{ item.stripeInvoiceId }}
        </span>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ma-2"
              text
              icon
              color="primary"
              :href="stripeDashboardUrl(item.stripeInvoiceId)"
              target="_blank"
            >
              <v-icon>link</v-icon>
            </v-btn>
          </template>
          <span>Stripe</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.ownedBy`]="{ item }">
        <router-link :to="{ name: 'Company', params: { id: item.ownedBy.id } }">
          {{ item.ownedBy.name }}
        </router-link>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" max-width="900" @click:outside="closeDialog">
      <v-card v-if="selectedOrder">
        <v-card-title>Order Details</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-title class="list-title">Type</v-list-item-title>
              <v-list-item-subtitle>
                {{
                  selectedOrder.type === "printTemplate"
                    ? "Print Template"
                    : "Additional Tree"
                }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.email"></v-divider>
            <v-list-item v-if="selectedOrder.email">
              <v-list-item-title class="list-title">
                User email
              </v-list-item-title>
              <v-list-item-subtitle>
                <span ref="email">{{ selectedOrder.email }}</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      @click.prevent="copyToClipboard($event, 'email')"
                    >
                      <img
                        svg-inline
                        src="../../assets/images/ico-copy.svg"
                        alt=""
                      />
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-if="selectedOrder.forest">
              <v-list-item-title class="list-title">Forest</v-list-item-title>
              <v-list-item-subtitle>
                <router-link
                  :to="{
                    name: 'Forest',
                    params: { id: selectedOrder.forest.id },
                  }"
                >
                  {{ selectedOrder.forest.name }}
                </router-link>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.stripeProductName"></v-divider>
            <v-list-item v-if="selectedOrder.stripeProductName">
              <v-list-item-title class="list-title">
                Product
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedOrder.stripeProductName }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title class="list-title">
                Ordered At
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ formatDate(selectedOrder.createdAt) }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.stripeInvoiceId"></v-divider>
            <v-list-item v-if="selectedOrder.stripeInvoiceId">
              <v-list-item-title class="list-title">
                Invoice ID
              </v-list-item-title>
              <v-list-item-subtitle>
                <span ref="stripeInvoiceId">
                  {{ selectedOrder.stripeInvoiceId }}
                </span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      @click.prevent="
                        copyToClipboard($event, 'stripeInvoiceId')
                      "
                    >
                      <img
                        svg-inline
                        src="../../assets/images/ico-copy.svg"
                        alt=""
                      />
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      color="primary"
                      :href="stripeDashboardUrl(selectedOrder.stripeInvoiceId)"
                      target="_blank"
                    >
                      <v-icon>link</v-icon>
                    </v-btn>
                  </template>
                  <span>Stripe</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.stripeInvoiceNumber"></v-divider>
            <v-list-item v-if="selectedOrder.stripeInvoiceNumber">
              <v-list-item-title class="list-title">
                Invoice Number
              </v-list-item-title>
              <v-list-item-subtitle>
                <span ref="stripeInvoiceNumber">
                  {{ selectedOrder.stripeInvoiceNumber }}
                </span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      @click.prevent="
                        copyToClipboard($event, 'stripeInvoiceNumber')
                      "
                    >
                      <img
                        svg-inline
                        src="../../assets/images/ico-copy.svg"
                        alt=""
                      />
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider
              v-if="selectedOrder.type === 'printTemplate'"
            ></v-divider>
            <v-list-item v-if="selectedOrder.type === 'printTemplate'">
              <v-list-item-title class="list-title">Price</v-list-item-title>
              <v-list-item-subtitle>
                {{ selectedOrder.amount / 100 }} {{ selectedOrder.currency }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.fileUrl"></v-divider>
            <v-list-item v-if="selectedOrder.fileUrl">
              <v-list-item-title>Print Template File</v-list-item-title>
              <v-list-item-subtitle>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      color="primary"
                      :href="selectedOrder.fileUrl"
                      target="_blank"
                    >
                      <v-icon>file_present</v-icon>
                    </v-btn>
                  </template>
                  <span>File</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.templateConfiguration"></v-divider>
            <v-list-item v-if="selectedOrder.templateConfiguration">
              <v-list-item-title class="list-title">
                Template Configuration
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-list>
                  <template
                    v-for="(value, key) in selectedOrder.templateConfiguration"
                  >
                    <v-list-item :key="key">
                      <v-list-item-title class="list-title">
                        {{ formatKey(key) }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="typeof value !== 'object'">
                        <span v-if="value">
                          <span v-if="isUrl(value)">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  class="ma-2"
                                  text
                                  icon
                                  color="primary"
                                  :href="value"
                                  target="_blank"
                                >
                                  <v-icon>file_present</v-icon>
                                </v-btn>
                              </template>
                              <span>Logo</span>
                            </v-tooltip>
                          </span>
                          <span v-else>
                            {{ formatValue(value) }}
                          </span>
                        </span>
                        <span v-else>
                          {{ formatValue(value) }}
                        </span>
                        <v-divider
                          v-if="value && key !== Object.keys(value).length - 1"
                        ></v-divider>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        <template v-for="(value, key) in value">
                          <v-list-item :key="key">
                            <v-list-item-title class="list-title">
                              {{ formatKey(key) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ formatValue(value) }}
                              <v-divider
                                v-if="
                                  value && key !== Object.keys(value).length - 1
                                "
                              ></v-divider>
                            </v-list-item-subtitle>
                          </v-list-item>
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-list>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.shipping"></v-divider>
            <v-list-item v-if="selectedOrder.shipping">
              <v-list-item-title class="list-title">Shipping</v-list-item-title>
              <v-list-item-subtitle>
                <v-list>
                  <template v-for="(value, key) in selectedOrder.shipping">
                    <v-list-item :key="key">
                      <v-list-item-title class="list-title">
                        {{ formatKey(key) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ formatValue(value) }}
                        <v-divider
                          v-if="value && key !== Object.keys(value).length - 1"
                        ></v-divider>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-list>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="selectedOrder.references"></v-divider>
            <v-list-item v-if="selectedOrder.references">
              <v-list-item-title class="list-title">
                References
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-list>
                  <template v-for="(value, key) in selectedOrder.references">
                    <v-list-item :key="key">
                      <v-list-item-title class="list-title">
                        {{ formatKey(key) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="isUrl(value)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                class="ma-2"
                                text
                                icon
                                color="primary"
                                :href="value"
                                target="_blank"
                              >
                                <v-icon>link</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ formatKey(key) }}</span>
                          </v-tooltip>
                        </span>
                        <span v-else>
                          {{ formatValue(value) }}
                        </span>
                        <v-divider
                          v-if="value && key !== Object.keys(value).length - 1"
                        ></v-divider>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-list>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { API } from "../../api";
import { formatDate } from "../../helpers";
import copyText from "../../utils/copyText";

export default {
  name: "CompanyOrders",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      orders: [],
      totalAmount: 0,
      loading: false,
      search: "",
      timerId: null,
      types: [
        { id: "printTemplate", name: "Print Template" },
        { id: "additionalTree", name: "Additional Tree" },
      ],
      selectedType: null,
      headers: [
        {
          text: "Order",
          value: "type",
          width: 250,
        },
        {
          text: "Invoice",
          value: "stripeInvoiceId",
          width: 250,
          sortable: false,
        },
        {
          text: "Product",
          value: "stripeProductName",
          width: 250,
        },
        {
          text: "Ordered At",
          value: "createdAt",
          width: 250,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        previousPage: 0,
      },
      showDialog: false,
      selectedOrder: null,
    };
  },
  filters: {
    date(date) {
      if (!date) return "";
      return date.toISOString().substr(0, 10).split("-").reverse().join(".");
    },
  },
  computed: {
    ...mapGetters(["isProd"]),
    searchQuery() {
      const trimmedSearch = this.search.trim();
      return trimmedSearch === "" ? null : trimmedSearch;
    },
    ranges() {
      const startOfDay = new Date(new Date().setHours(2, 0, 0, 0));
      const endOfDay = new Date(new Date().setHours(23, 59, 59, 999));

      const thisMonthStart = new Date(
        startOfDay.getFullYear(),
        startOfDay.getMonth(),
        1
      );
      const thisMonthEnd = new Date(
        endOfDay.getFullYear(),
        endOfDay.getMonth() + 1,
        0
      );

      return {
        Today: [startOfDay, endOfDay],
        "Last 7 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 7
          ),
          endOfDay,
        ],
        "Last 30 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 30
          ),
          endOfDay,
        ],
        "This month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(startOfDay.getFullYear(), 0, 1),
          new Date(endOfDay.getFullYear(), 11, 31),
        ],
        "Last month": [
          new Date(startOfDay.getFullYear(), startOfDay.getMonth() - 1, 1),
          new Date(endOfDay.getFullYear(), endOfDay.getMonth(), 0),
        ],
        "Last Year": [
          new Date(startOfDay.getFullYear() - 1, 0, 1),
          new Date(endOfDay.getFullYear() - 1, 11, 31),
        ],
      };
    },
  },
  created() {
    this.getOrders();
  },
  watch: {
    options: {
      handler: "debounceFetching",
      deep: true,
    },
    search: {
      handler: "debounceFetching",
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getOrders();
      }, 500);
    },
    async getOrders() {
      this.loading = true;
      try {
        const queryParams = {
          ownedBy: this.id,
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
          ...this.dateRange,
          query: this.searchQuery,
        };

        if (this.selectedType) {
          queryParams.type = this.selectedType;
        }

        const getOrdersForTable = await API.getOrders(queryParams);

        this.orders = getOrdersForTable.data;
        this.totalAmount = getOrdersForTable.count;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    showOrderDetails(order) {
      this.selectedOrder = order;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedOrder = null;
    },
    formatKey(key) {
      // Convert camelCase to Title Case
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },
    formatValue(value) {
      if (value === null) return "None";
      if (typeof value === "boolean") return value ? "Yes" : "No";
      if (typeof value === "object") return JSON.stringify(value, null, 2);
      return value;
    },
    stripeDashboardUrl(stripeInvoiceId) {
      return this.isProd
        ? `https://dashboard.stripe.com/invoices/${stripeInvoiceId}`
        : `https://dashboard.stripe.com/test/invoices/${stripeInvoiceId}`;
    },
    isUrl(value) {
      try {
        return (
          typeof value === "string" &&
          (value.startsWith("http://") ||
            value.startsWith("https://") ||
            value.startsWith("www."))
        );
      } catch {
        return false;
      }
    },
    copyToClipboard(e, key) {
      if (this.$refs[key]) {
        copyText({ target: this.$refs[key], trigger: e.currentTarget });
      }
    },
  },
};
</script>

<style scoped>
.list-title {
  max-width: 200px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-list-item-subtitle {
  font-size: 0.9rem; /* Adjust this value as needed */
  line-height: 1.2;
}
</style>
