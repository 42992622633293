<template>
  <v-card>
    <v-card-title>
      Invoice
      <v-spacer></v-spacer>

      <v-menu v-model="openActions" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-show="isNotGuest" v-on="on" color="primary">Actions</v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                :disabled="!invoice.refundable"
                class="ma-2"
                text
                color="primary"
                :to="{
                  name: 'InvoiceCreditNote',
                  params: { id: invoice.stripeInvoiceId },
                }"
              >
                Refund
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                :disabled="!invoice.addressEditable"
                class="ma-2"
                text
                color="primary"
                @click="editAddress = true"
              >
                Update Address
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <template>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="width: 200px">Id</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                <span ref="stripeInvoiceId">{{ invoice.stripeInvoiceId }}</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      v-if="invoice.stripeInvoiceId"
                      @click.prevent="copyStripeInvoiceId"
                    >
                      <img
                        svg-inline
                        src="../../assets/images/ico-copy.svg"
                        alt=""
                      />
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>Number</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                {{ invoice.invoiceNumber }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      color="primary"
                      :href="`https://dashboard.stripe.com/invoices/${invoice.stripeInvoiceId}`"
                      target="_blank"
                    >
                      <v-icon>link</v-icon>
                    </v-btn>
                  </template>
                  <span>Stripe</span>
                </v-tooltip>
              </td>
            </tr>
            <tr
              v-if="invoice.metadata && invoice.metadata.originalInvoiceNumber"
            >
              <td>Original</td>
              <td>
                {{ invoice.metadata.originalInvoiceNumber }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      color="primary"
                      :href="`https://dashboard.stripe.com/invoices/${invoice.metadata.originalInvoiceId}`"
                      target="_blank"
                    >
                      <v-icon>link</v-icon>
                    </v-btn>
                  </template>
                  <span>Stripe</span>
                </v-tooltip>
              </td>
            </tr>
            <tr v-if="updatedStripeInvoice">
              <td>Updated</td>
              <td>
                {{ updatedStripeInvoice.stripeInvoiceNumber }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      color="primary"
                      :href="`https://dashboard.stripe.com/invoices/${updatedStripeInvoice.stripeInvoiceId}`"
                      target="_blank"
                    >
                      <v-icon>link</v-icon>
                    </v-btn>
                  </template>
                  <span>Stripe</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>Language</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                {{ invoice.language }}
              </td>
            </tr>
            <tr>
              <td>Created at</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                <span v-else>
                  {{ new Date(invoice.created * 1000) }}
                </span>
              </td>
            </tr>
            <tr>
              <td>Current Invoice Status</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                {{ invoice.status }}
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                {{ invoice.priceDescription }}
              </td>
            </tr>
            <tr>
              <td>Type</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                {{ invoice.type }}
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                <span v-else>
                  {{ invoice.total / 100 }} {{ invoice.currency }}
                </span>
              </td>
            </tr>
            <tr>
              <td>Files</td>
              <td>
                <v-btn
                  v-if="invoice"
                  class="ma-2"
                  text
                  icon
                  color="primary"
                  @click="downloadInvoice()"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                <v-skeleton-loader
                  v-if="isLoading"
                  type="text"
                  width="200px"
                ></v-skeleton-loader>
                <template v-else-if="invoice.customerAddress">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td style="width: 350px">Name</td>
                        <td>{{ invoice.customerName }}</td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td>{{ invoice.customerAddress.city }}</td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>
                          <span v-if="invoice.customerAddress.length === 2">
                            {{
                              selectedCountry(invoice.customerAddress.country)
                                .name
                            }}
                          </span>
                          <span v-else>
                            {{ invoice.customerAddress.country }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Line 1 e.g., street, PO Box, or company name</td>
                        <td>{{ invoice.customerAddress.line1 }}</td>
                      </tr>
                      <tr>
                        <td>
                          Line 2 e.g., apartment, suite, unit, or building
                        </td>
                        <td>{{ invoice.customerAddress.line2 }}</td>
                      </tr>
                      <tr>
                        <td>Postal Code</td>
                        <td>{{ invoice.customerAddress.postal_code }}</td>
                      </tr>
                      <tr>
                        <td>State, province, or region</td>
                        <td>{{ invoice.customerAddress.state }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>
              </td>
            </tr>
            <tr v-if="invoice.creditNotesAmount">
              <td>Credit Notes Amount</td>
              <td>
                {{ invoice.creditNotesAmount / 100 }}
                {{ invoice.currency }}
              </td>
            </tr>
            <tr v-if="invoice.creditNotes && invoice.creditNotes.length > 0">
              <td>Credit Notes History</td>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td style="width: 5px">Date</td>
                    <td style="width: 5px">Credit Note</td>
                    <td style="width: 5px">Amount</td>
                    <td style="width: 5px">PDF</td>
                  </tr>
                  <tr v-for="line in invoice.creditNotes" :key="line.id">
                    <td>{{ line.createdAt }}</td>
                    <td>
                      <a
                        :href="`https://dashboard.stripe.com/credit_notes/${line.stripeCreditNoteId}`"
                        target="_blank"
                      >
                        {{
                          line.stripeCreditNoteNumber || line.stripeCreditNoteId
                        }}
                      </a>
                    </td>
                    <td>{{ line.total / 100 }} {{ invoice.currency }}</td>
                    <td>
                      <v-btn
                        v-if="line.stripeCreditNoteId"
                        class="ma-2"
                        text
                        icon
                        color="primary"
                        @click="downloadCreditNote(line.stripeCreditNoteId)"
                      >
                        <v-icon>picture_as_pdf</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>

    <template>
      <v-dialog v-model="editAddress" persistent max-width="650px">
        <v-card>
          <v-card-title>Edit Invoice Address</v-card-title>
          <v-card-text v-if="invoiceAddress">
            <v-form>
              <v-text-field v-model="invoiceAddress.name" label="Name">
              </v-text-field>
              <v-text-field
                v-model="invoiceAddress.city"
                :rules="[requiredRule]"
                label="City"
              ></v-text-field>
              <v-select
                v-model="invoiceAddress.country"
                :items="countryList"
                item-text="name"
                item-value="code"
                label="Select a country"
                search-input
              ></v-select>
              <v-text-field
                v-model="invoiceAddress.line1"
                :rules="[requiredRule]"
                label="e.g., street, PO Box, or company name"
              ></v-text-field>
              <v-text-field
                v-model="invoiceAddress.line2"
                label="e.g., apartment, suite, unit, or building"
              ></v-text-field>
              <v-text-field
                v-model="invoiceAddress.postal_code"
                :rules="[requiredRule]"
                label="Postal Code"
              ></v-text-field>
              <v-text-field
                v-model="invoiceAddress.state"
                label="State, province, or region"
              ></v-text-field>
              <v-textarea
                v-model="invoiceAddress.description"
                clearable
                clear-icon="close"
                label="Description, referenced as 'memo' in the Dashboard"
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="editAddress = false"
            >
              Cancel
            </v-btn>
            <v-btn color="primary" @click="editAddressConfirmation = true">
              <v-progress-circular
                v-if="loading"
                indeterminate
                size="20"
                width="2"
                color="white"
              ></v-progress-circular>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="editAddressConfirmation" persistent max-width="600px">
        <v-card>
          <v-card-title class="text-center">
            Are you sure you want to update this invoice?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="editAddressConfirmation = false"
            >
              Cancel
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" @click="confirmForm">
              Confirm
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { API } from "../../api";
import { formatDate2 } from "../../helpers";
import countryList from "../../utils/countryList.js";
import copyText from "../../utils/copyText";

export default {
  name: "InvoiceDetails",
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countryList,
      formatDate2,
      requiredRule: (v) => !!v || "This field is required",
      editAddressConfirmation: false,
      loading: false,
      editAddress: false,
      openActions: false,
      invoiceAddress: null,
      isLoading: true,
    };
  },
  watch: {
    invoice: {
      immediate: true,
      async handler(val) {
        if (val?.ownedBy) {
          this.isLoading = true;
          this.invoiceAddress = null;
          try {
            const { stripeCustomer } = await API.getBillingInfo(val.ownedBy);

            this.invoiceAddress = {
              ...stripeCustomer.address,
              name: stripeCustomer.name,
              description: this.description,
            };

            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters(["isNotGuest"]),
    description() {
      if (this.invoice?.language === "de") {
        return `Hiermit korrigieren wir die Rechnung mit der Nummer ${
          this.invoice.metadata?.originalInvoiceNumber ??
          this.invoice.invoiceNumber
        } vom ${formatDate2(
          (this.invoice.metadata?.originalInvoiceCreated ??
            this.invoice.created) * 1000
        )} und stellen folgende Leistungen in Rechnung. Der Betrag muss nicht gezahlt werden, da er mit der Zahlung der vorherigen Rechnung bereits beglichen wurde.`;
      }
      return `We have adjusted your invoice number ${
        this.invoice?.metadata?.originalInvoiceNumber ??
        this.invoice?.invoiceNumber
      } dated ${formatDate2(
        (this.invoice.metadata?.originalInvoiceCreated ??
          this.invoice.created) * 1000
      )} according to your updated information. There is no additional payment obligation as the amount has been covered by the payment of the original invoice.`;
    },
    updatedStripeInvoice() {
      if (!this.invoice.newStripeInvoiceId) {
        return null;
      }
      return {
        stripeInvoiceId:
          this.invoice.metadata?.newInvoiceId ??
          this.invoice.newStripeInvoiceId,
        stripeInvoiceNumber:
          this.invoice.metadata?.newInvoiceNumber ??
          this.invoice.newStripeInvoiceId,
      };
    },
  },
  methods: {
    selectedCountry(countryCode) {
      return this.countryList.find((country) => country.code === countryCode);
    },
    async confirmForm() {
      this.loading = true;
      this.editAddressConfirmation = false;

      const filteredObject = {};
      for (const key in this.invoiceAddress) {
        if (this.invoiceAddress[key] && this.invoiceAddress[key] !== null) {
          filteredObject[key] = this.invoiceAddress[key];
        }
      }

      try {
        await API.updateInvoiceAddress({
          id: this.invoice?.stripeInvoiceId,
          address: filteredObject,
        });
        this.loading = false;
        this.editAddress = false;

        this.$router.push({
          name: "Company",
          params: { id: this.invoice.ownedBy },
        });
      } catch (error) {
        this.loading = false;
      }
    },
    async downloadInvoice() {
      this.loading = true;

      try {
        const pdfUrl = await API.getInvoicePdf(this.invoice.stripeInvoiceId);
        this.loading = false;
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
      }
    },
    async downloadCreditNote(id) {
      this.loading = true;

      try {
        const pdfUrl = await API.getInvoiceCreditNotePdf({
          stripeInvoiceId: this.invoice.stripeInvoiceId,
          stripeCreditNoteId: id,
        });
        this.loading = false;
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
      }
    },
    copyStripeInvoiceId(e) {
      copyText({
        target: this.$refs.stripeInvoiceId,
        trigger: e.currentTarget,
      });
    },
  },
};
</script>
