import { render, staticRenderFns } from "./PieChartProject.vue?vue&type=template&id=34a4d0b8&scoped=true&"
import script from "./PieChartProject.vue?vue&type=script&lang=js&"
export * from "./PieChartProject.vue?vue&type=script&lang=js&"
import style0 from "./PieChartProject.vue?vue&type=style&index=0&id=34a4d0b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a4d0b8",
  null
  
)

export default component.exports