import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Review Forests "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.forests,"server-items-length":_vm.totalAmount,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.$options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
    },"fixed-header":"","options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Forest', params: { id: item._id } }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.slug",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":((_vm.$options.FOREST_DOMAIN) + "/" + (item.slug)),"target":"_blank"}},[_vm._v(_vm._s(((_vm.$options.FOREST_DOMAIN) + "/" + (item.slug))))])]}},{key:"item.platforms",fn:function(ref){
    var item = ref.item;
return [(item.type === 'single')?_c('span',[_c('platforms-logo',{attrs:{"platforms":item.platformsOrder}})],1):_vm._e(),_c('span')]}},{key:"item.reviewAmount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.reviewAmount || 0)+" ")]}},{key:"item.reviewAmountOnPlatform",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.reviewAmountOnPlatform || 0)+" ")]}},{key:"item.projectId",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.PLANTING_PROJECTS[item.projectId || 1])+" ")]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? "Active" : "Deactivated")+" ")]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }