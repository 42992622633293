<template>
  <v-card>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="12" md="3">List of Emails</v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="selectedStatus"
            :items="statuses"
            item-text="name"
            item-value="id"
            label="Select status"
            clearable
            class="ml-4"
            @change="debounceFetching"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="selectedType"
            :items="types"
            item-text="name"
            item-value="id"
            label="Select type"
            clearable
            class="ml-4"
            @change="debounceFetching"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="emails"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalAmount"
      :footer-props="{
        'items-per-page-options': $options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      }"
    >
      <template v-slot:[`item.recipientNumber`]="{ item }">
        <router-link
          v-if="item.ownedBy"
          :to="{ name: 'Company', params: { id: item.ownedBy.id } }"
        >
          {{ item.recipientNumber }}
        </router-link>
        <router-link
          v-else-if="item.userId"
          :to="{ name: 'User', params: { id: item.userId } }"
        >
          {{ item.recipientNumber }}
        </router-link>
        <span v-else>
          {{ item.recipientNumber }}
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ statuses.find((status) => status.id === item.status).name }}
            </span>
          </template>
          <span>
            <ul>
              <li v-for="sgLog in item.sgLogs" :key="sgLog.sg_event_id">
                {{ sgLog.event }} - {{ formatDate(sgLog.timestamp * 1000) }}
              </li>
            </ul>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>
      <template v-slot:[`item.sendAt`]="{ item }">
        {{ formatDate(item.sendAt) }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ types.find((type) => type.id === item.type).name }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { API } from "../../api";
import { formatDate } from "../../helpers";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTIONS,
  EMAIL_TYPES,
  SENDGRID_EMAIL_STATUS,
} from "../../constants";

export default {
  name: "EmailsTable",
  DEFAULT_ITEMS_PER_PAGE_OPTIONS,
  data() {
    return {
      formatDate,
      timerId: null,
      loading: true,
      headers: [
        {
          text: "Recipient",
          value: "recipientNumber",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Sent",
          sortable: true,
          value: "createdAt",
          width: "200",
        },
        {
          text: "Scheduled at",
          sortable: true,
          value: "sendAt",
          width: "200",
        },
        {
          text: "Subject",
          sortable: false,
          value: "subject",
        },
        {
          text: "Type",
          sortable: false,
          value: "type",
        },
      ],
      emails: [],
      totalAmount: 0,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      selectedType: null,
      types: EMAIL_TYPES,
      selectedStatus: null,
      statuses: SENDGRID_EMAIL_STATUS,
    };
  },
  computed: {
    searchQuery() {
      const trimmedSearch = this.search.trim();
      return trimmedSearch === "" ? null : trimmedSearch;
    },
  },
  watch: {
    search: {
      handler: "debounceFetching",
    },
    options: {
      handler: "debounceFetching",
      deep: true,
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getEmails();
      }, 500);
    },
    async getEmails() {
      this.loading = true;
      try {
        const queryParams = {
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
          query: this.searchQuery,
        };

        if (this.selectedType) {
          queryParams.type = this.selectedType;
        }

        if (this.selectedStatus) {
          queryParams.status = this.selectedStatus;
        }

        const emails = await API.getEmails(queryParams);
        this.emails = emails.data;
        this.totalAmount = emails.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
