import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex align-center"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"3"}},[_vm._v("List of Emails")]),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VSelect,{staticClass:"ml-4",attrs:{"items":_vm.statuses,"item-text":"name","item-value":"id","label":"Select status","clearable":""},on:{"change":_vm.debounceFetching},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VSelect,{staticClass:"ml-4",attrs:{"items":_vm.types,"item-text":"name","item-value":"id","label":"Select type","clearable":""},on:{"change":_vm.debounceFetching},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1)],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.emails,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalAmount,"footer-props":{
      'items-per-page-options': _vm.$options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.statuses.find(function (status) { return status.id === item.status; }).name)+" ")])]}}],null,true)},[_c('span',[_c('ul',_vm._l((item.sgLogs),function(sgLog){return _c('li',{key:sgLog.sg_event_id},[_vm._v(" "+_vm._s(sgLog.event)+" - "+_vm._s(_vm.formatDate(sgLog.timestamp * 1000))+" ")])}),0)])])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}},{key:"item.sendAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.sendAt))+" ")]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.types.find(function (type) { return type.id === item.type; }).name)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }