<template>
  <orders></orders>
</template>

<script>
import Orders from "../components/Order/Orders.vue";

export default {
  name: "OrdersView",
  components: {
    Orders,
  },
};
</script>
